<template>
  <div class="content"></div>
</template>

<script>
export default {
  name: 'Information',
  components: {},
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
.content {
  width: @uni-width;
  height: 1054px;
  margin: 0 auto;
  //background: url('~@/assets/information/content.png');
  background-size: 1200px;
}
</style>
